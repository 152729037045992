import React, {useEffect, useState} from 'react';
import VideoSubject from "../components/VideoSubject";
import {fetchTitleSubject} from "../api/TitleSubject";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Container, Fab, Stack, Tab} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import TitleSuggest from "../components/TitleSuggest";
import WatchSubject from "../components/WatchSubject";
import withWidth from "@mui/material/Hidden/withWidth";
import {QueueMusic} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {writeVideoEmotion} from "../api/VideoEmotion";

const TitleSubject = ({width}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [queryParam] = useSearchParams();

    const getMusicVideo = (location) => {
        return location.state && location.state.hasOwnProperty(
            'musicVideo') && location.state.musicVideo
                    ? location.state.musicVideo
                            : queryParam.get('v');
    }

    const getMusicQueue = (location) => {
        return location.state && location.state.hasOwnProperty(
            'musicQueue') && location.state.musicQueue
                    ? location.state.musicQueue : [];
    }

    const getMusicPanel = (location) => {
        return location.state && location.state.hasOwnProperty(
            'musicPanel') && location.state.musicPanel
                    ? location.state.musicPanel :  0;
    }

    const getWatchVideo = (response) => {
        return response.watchSubject;
    }

    const getWatchTitle = (response) => {
        return response.titleSubject;
    }

    const getCoverBlock = (response) => {
        return response.coverSubject;
    }

    const getMatchBlock = (response) => {
        return response.matchSubject;
    }

    const [musicVideo, setMusicVideo] = useState(
        getMusicVideo(location));

    const [musicQueue, setMusicQueue] = useState(
        getMusicQueue(location));

    const [musicPanel, setMusicPanel] = useState(
        getMusicPanel(location));

    if (musicVideo !== getMusicVideo(location)) {
        setMusicVideo (getMusicVideo(location));
        setMusicQueue (getMusicQueue(location));
        setMusicPanel (getMusicPanel(location));
    }

    const [watchTitle, setWatchTitle] = useState({});
    const [watchVideo, setWatchVideo] = useState({});

    const [coverBlock, setCoverBlock] = useState([]);
    const [matchBlock, setMatchBlock] = useState([]);

    const updatePage = async (musicVideo) => {
        try {
            const response = await fetchTitleSubject(
                musicVideo
            );

            if (musicQueue.length === 0) {
                setWatchVideo(getWatchVideo(response));
                setWatchTitle(getWatchTitle(response));
                setCoverBlock(getCoverBlock(response));
                setMatchBlock(getMatchBlock(response));

                setMusicQueue(
                    getMatchBlock(response));
            } else {
                setWatchVideo(getWatchVideo(response));
                setWatchTitle(getWatchTitle(response));
                setCoverBlock(getCoverBlock(response));
                setMatchBlock(getMatchBlock(response));
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                navigate('/lg')
            } else {
                console.error(
                    "Error fetching title subject: ", error);
            }
        }
    }

    useEffect(() => {
        updatePage(musicVideo).then();
    }, [
        musicVideo]
    );

    const onVideoFinished = (onError) => {
        if (onError === 0) {
            onPlayNextVideo();
        }
    }

    const onPlayPrevVideo = () => {
        navigate(-1);
    }

    const onPlayNextVideo = (replace = 0) => {
        let queueIndex = getPlayNextIndex();
        if (queueIndex >= 0 && queueIndex < musicQueue.length) {
            navigateToQueue(
                musicQueue[queueIndex], replace);
        }
    }

    const getPlayNextIndex = () => {
        let queueIndex = musicQueue.findIndex(
            (video) => {
                return video.musicVideo === musicVideo;
            })
        return queueIndex < 0 ? 0 : (queueIndex + musicQueue.length + 1) % musicQueue.length;
    }

    const onVideoFeedback = (video, saved) => {
        try {
            writeVideoEmotion(video.musicVideo, saved).then(
                () => {
                    updatePage(musicVideo).then()
                }
            )
        } catch (error) {
            if (error?.response?.status === 401) {
                navigate('/lg')
            } else {
                console.error(
                    "Error writing video emotion: ", error);
            }
        }
    }

    const onInputSelected = (
        video) => {
        let replace = 0;
        navigateToInput(
            video, replace);
    }

    const onVideoSelected = (video) => {
        let replace = 0;
        navigateToVideo(video, replace);
    }

    const onTitleSelected = (title) => {
        let replace = 0;
        navigateToTitle(title, replace);
    }

    const onOwnerSelected = (video) => {
        let replace = 0;
        navigateToOwner(video, replace);
    }

    const navigateToInput = (
        video, replace) => {
        let musicVideo = video;

        navigate(`/ts?v=${musicVideo}`,
            {
                state: {
                    musicVideo: musicVideo,
                },
                replace: replace
            });
    }

    const navigateToVideo = (
        video, replace) => {
        let musicVideo = video.musicVideo;

        navigate(`/ts?v=${musicVideo}`,
            {
                state: {
                    musicVideo: musicVideo
                },
                replace: replace
            });

        window.scrollTo({top: 0, left: 0});
    }

    const navigateToTitle = (
        title, replace) => {
        let musicVideo = title.titleVideo;

        navigate(`/ts?v=${musicVideo}`,
            {
                state: {
                    musicVideo: musicVideo
                },
                replace: replace
            });

        window.scrollTo({top: 0, left: 0});
    }

    const navigateToQueue = (
        video, replace) => {
        let musicVideo = video.musicVideo;

        navigate(`/ts?v=${musicVideo}`,
            {
                state: {
                    musicVideo: musicVideo,
                    musicQueue: musicQueue,
                },
                replace: replace
            });
    }

    const navigateToOwner = (
        video, replace) => {
        let musicOwner = video.musicOwner;

        navigate(`/os?o=${musicOwner}`,
            {
                replace: replace
            });
    }

    const watchFilter = (video) => {
        return video.musicVideo !== musicVideo;
    }

    const titleFilter = (video) => {
        return video.trackTitle !== watchTitle?.trackTitle
            || video.actorTitle !== watchTitle?.actorTitle;
    }

    const isSmallScreen = /xs|sm/.test(width);

    if (isSmallScreen) {
        return (
            watchVideo.musicVideo &&
            <React.Fragment sx={{ position: 'relative' }}>
                <TitleSuggest key={musicVideo} onInputSelected={onInputSelected}/>

                <Stack>
                    {
                        <WatchSubject
                            video={watchVideo}
                            title={watchTitle}
                            onTitleSelected={onTitleSelected}
                            onOwnerSelected={onOwnerSelected}
                            onVideoFinished={onVideoFinished}
                            onPlayPrevVideo={onPlayPrevVideo}
                            onPlayNextVideo={onPlayNextVideo}
                            onVideoFeedback={onVideoFeedback}
                        />
                    }

                    {
                        (matchBlock.filter(titleFilter).length > 0 || coverBlock.filter(watchFilter).length > 0) &&
                        <TabContext value={musicPanel === 0  && coverBlock.filter(watchFilter).length > 0 ? '0' : '1'} >
                            <Box>
                                <TabList variant='fullWidth' onChange={(event, value) => {setMusicPanel(value === '0' ? 0 : 1)}}>
                                    {
                                        coverBlock.filter(watchFilter).length > 0 &&
                                        <Tab value='0' label='FROM TITLE' sx={{ borderBottom: 1, borderColor: 'divider' }}></Tab>
                                    }

                                    {
                                        matchBlock.filter(titleFilter).length > 0 &&
                                        <Tab value='1' label='LIKE TITLE' sx={{ borderBottom: 1, borderColor: 'divider' }}></Tab>
                                    }
                                </TabList>
                            </Box>

                            <TabPanel value='0' sx={{ p: 0 }}>
                                <Grid container xs={12}>
                                    {coverBlock.filter(watchFilter)?.map((blockVideo, blockIndex) => (
                                        <Grid key={blockIndex} xs={12} sx={{ px: {xs: 0, md: 2}, py: 1 }}>
                                            <VideoSubject
                                                video={blockVideo}
                                                onVideoSelected={onVideoSelected}
                                                onOwnerSelected={onOwnerSelected}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </TabPanel>

                            <TabPanel value='1' sx={{ p: 0 }}>
                                <Grid container xs={12}>
                                    {matchBlock.filter(titleFilter)?.map((blockVideo, blockIndex) => (
                                        <Grid key={blockIndex} xs={12} sx={{ px: {xs: 0, md: 2}, py: 1 }}>
                                            <VideoSubject
                                                video={blockVideo}
                                                onVideoSelected={onVideoSelected}
                                                onOwnerSelected={onOwnerSelected}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </TabPanel>
                        </TabContext>
                    }

                    {
                        (matchBlock.filter(titleFilter).length > 0 || coverBlock.filter(watchFilter).length > 0) &&
                        <Fab sx = {{ position: 'fixed', bottom: 30, right: 30, border: 2 }}
                             disabled={JSON.stringify(musicQueue) === JSON.stringify(
                                 musicPanel === 0 ? coverBlock : matchBlock)}
                             color = 'primary'
                             onClick = {() => {
                                 setMusicQueue(musicPanel === 0 ? coverBlock : matchBlock)
                             }}>
                            <QueueMusic/>
                        </Fab>
                    }
                </Stack>

            </React.Fragment>
        );
    } else {
        return (
            watchVideo.musicVideo &&
            <React.Fragment>
                <Container maxWidth='xl'>
                    <TitleSuggest key={musicVideo} onInputSelected={onInputSelected}/>

                    <Grid container>
                        {
                            <Grid xs>
                                <Stack>
                                    {
                                        <WatchSubject
                                            video={watchVideo}
                                            title={watchTitle}
                                            onTitleSelected={onTitleSelected}
                                            onOwnerSelected={navigateToOwner}
                                            onVideoFinished={onVideoFinished}
                                            onPlayPrevVideo={onPlayPrevVideo}
                                            onPlayNextVideo={onPlayNextVideo}
                                            onVideoFeedback={onVideoFeedback}
                                        />
                                    }

                                    {
                                        (matchBlock.filter(titleFilter).length > 0 || coverBlock.filter(watchFilter).length > 0) &&
                                        <Grid container xs={12}>
                                            {(musicPanel === 0
                                                    ? matchBlock.filter(titleFilter)
                                                    : coverBlock.filter(watchFilter)
                                            )?.map((blockVideo, blockIndex) => (
                                                <Grid key={blockIndex} xs={12} sm={6} lg={4} sx={{ px: {xs: 0, md: 2}, py: 1 }}>
                                                    <VideoSubject
                                                        video={blockVideo}
                                                        onVideoSelected={onVideoSelected}
                                                        onOwnerSelected={onOwnerSelected}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    }
                                </Stack>
                            </Grid>
                        }

                        {
                            (matchBlock.filter(titleFilter).length > 0 || coverBlock.filter(watchFilter).length > 0) &&
                            <Grid xs='auto' style={{width: '320px'}} sx={{ pl: 2 }}>
                                <Stack>
                                    <TabContext value={musicPanel === 0 && coverBlock.filter(watchFilter).length > 0 ? '0' : '1'} >
                                        <Box>
                                            <TabList variant='fullWidth' onChange={(event, value) => {setMusicPanel(value === '0' ? 0 : 1)}}>
                                                {
                                                    coverBlock.filter(watchFilter).length > 0 &&
                                                    <Tab value='0' label='FROM TITLE' sx={{ borderBottom: 1, borderColor: 'divider' }}></Tab>
                                                }

                                                {
                                                    matchBlock.filter(titleFilter).length > 0 &&
                                                    <Tab value='1' label='LIKE TITLE' sx={{ borderBottom: 1, borderColor: 'divider' }}></Tab>
                                                }
                                            </TabList>
                                        </Box>

                                        <TabPanel value='0' sx={{ p: 0 }}>
                                            <Grid container xs={12}>
                                                {coverBlock.filter(watchFilter)?.map((blockVideo, blockIndex) => (
                                                    <Grid key={blockIndex} xs={12} sx={{ py: 1 }}>
                                                        <VideoSubject
                                                            video={blockVideo}
                                                            onVideoSelected={onVideoSelected}
                                                            onOwnerSelected={onOwnerSelected}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </TabPanel>

                                        <TabPanel value='1' sx={{ p: 0 }}>
                                            <Grid container xs={12}>
                                                {matchBlock.filter(titleFilter)?.map((blockVideo, blockIndex) => (
                                                    <Grid key={blockIndex} xs={12} sx={{ py: 1 }}>
                                                        <VideoSubject
                                                            video={blockVideo}
                                                            onVideoSelected={onVideoSelected}
                                                            onOwnerSelected={onOwnerSelected}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </TabPanel>
                                    </TabContext>

                                    <Fab sx = {{ position: 'fixed', bottom: 30, right: 30, border: 2 }}
                                         disabled={JSON.stringify(musicQueue) === JSON.stringify(
                                             musicPanel === 0 ? coverBlock : matchBlock)}
                                         color = 'primary'
                                         onClick = {() => {
                                             setMusicQueue(musicPanel === 0 ? coverBlock : matchBlock)
                                         }}>
                                        <QueueMusic/>
                                    </Fab>

                                </Stack>
                            </Grid>
                        }
                    </Grid>

                </Container>
            </React.Fragment>
        );
    }
};

export default withWidth()(TitleSubject);
