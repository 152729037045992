import React, {useEffect, useState} from 'react';
import {fetchTitleExplore} from '../api/TitleExplore';
import TitleSuggest from "../components/TitleSuggest";
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {Container} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import withWidth from "@mui/material/Hidden/withWidth";
import VideoSubject from "../components/VideoSubject";

const TitleExplore = ({width}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [queryParam] = useSearchParams();

    const getBatchIndex = (location) => {
        return location.state && location.state.hasOwnProperty(
            'batchIndex') && location.state.batchIndex
                    ? location.state.batchIndex
                            : Math.floor(Math.random() * 16);
    }

    const getTitleLingo = (location) => {
        return location.state && location.state.hasOwnProperty(
            'titleLingo') && location.state.titleLingo
                    ? location.state.titleLingo
                            : (queryParam.has('hl') ? queryParam.get('hl') : 'en');
    }

    const [batchIndex, setBatchIndex] = useState(
        getBatchIndex(location));
    const [titleLingo, setTitleLingo] = useState(
        getTitleLingo(location));

    if (titleLingo !== getTitleLingo(location)) {
        setBatchIndex(setBatchIndex(location))
        setTitleLingo(getTitleLingo(location))
    }

    const [videoBlock, setVideoBlock] = useState([]);

    useEffect(() => {
        const updatePage = async (
            batchIndex,
            titleLingo
        ) => {
            try {
                const response = await fetchTitleExplore(
                    batchIndex,
                    titleLingo
                );

                setVideoBlock(response.videoSubject);
            } catch (error) {
                if (error?.response?.status === 401) {
                    navigate('/lg',
                        {
                            state: {
                                batchIndex: batchIndex,
                                titleLingo: titleLingo,
                                earlyLogin: true
                            },
                            replace: true
                        })
                } else {
                    console.error(
                        "Error fetching title explore: ", error);
                }
            }
        };

        updatePage(
            batchIndex,
            titleLingo).then();
    }, [
        batchIndex,
        titleLingo]);

    const onInputSelected = (video) => {
        let replace = 0;
        navigateToInput(video, replace);
    }

    const onVideoSelected = (video) => {
        let replace = 0;
        navigateToVideo(video, replace);
    };

    const onOwnerSelected = (video) => {
        let replace = 0;
        navigateToOwner(video, replace);
    }

    const navigateToInput = (
        video, replace) => {
        let musicVideo = video;

        navigate(`/ts?v=${musicVideo}`,
            {
                state: {
                    musicVideo: musicVideo,
                },
                replace: replace
            });
    }

    const navigateToVideo = (
        video, replace) => {
        let musicVideo = video.musicVideo;

        navigate(`/ts?v=${musicVideo}`,
            {
                state: {
                    musicVideo: musicVideo
                },
                replace: replace
            });
    }

    const navigateToOwner = (
        video, replace) => {
        let musicOwner = video.musicOwner;

        navigate(`/os?o=${musicOwner}`,
            {
                replace: replace
            });
    }

    const isSmallScreen = /xs|sm/.test(width);

    return (
        <React.Fragment>
            <Container maxWidth='lg' disableGutters={isSmallScreen}>
                <TitleSuggest key={batchIndex} onInputSelected={onInputSelected} />

                <Grid container>
                    {videoBlock?.map((video, index) => (
                        <Grid key={index} xs={12} md={6} lg={4} sx={{ px: {xs: 0, md: 2}, py: 1 }}>
                            <VideoSubject
                                video={video}
                                onVideoSelected={onVideoSelected}
                                onOwnerSelected={onOwnerSelected}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>

        </React.Fragment>
    );
};

export default withWidth()(TitleExplore);
