import React from 'react';
import {CardMedia, Chip, Stack, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {AutoAwesome} from "@mui/icons-material";

const VideoSubject = (
    {
        video,
        onVideoSelected,
        onOwnerSelected
    }) => {
    console.log(video)

    return (
        <Grid container xs={12}>
            <Grid xs={12}>
                <CardMedia
                    style={{backgroundColor: '#eeeeee', cursor: 'pointer'}}
                    component='img'
                    image={video.lsizeThumb ? video.lsizeThumb : video.msizeThumb}
                    alt  ={`${video.videoTitle} thumbnail`}
                    title={`${video.videoTitle} thumbnail`}
                    sx={{ borderRadius: {xs: '0px', sm: '12px'} }}
                    onClick={() => onVideoSelected(video)}
                />
            </Grid>

            <Grid container xs={12} sx={{pl: {xs: 2, md: 0}, pt: 1 }} alignItems='center'>
                <Grid xs>
                    <Stack direction='row' alignItems='center' sx={{
                                overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical'}}>
                        <Chip label={
                            <Typography component='span' variant='h7' sx={{ fontWeight: 600 }}>
                                {video.trackTitle}
                            </Typography>
                        } onClick={() => onVideoSelected(video)} />

                        {
                            <Typography component='span' variant='h7' sx={{ fontWeight: 200 }}
                                        whiteSpace='pre' color='#cccccc'>
                                {' ~ '}
                            </Typography>
                        }

                        {
                            video.magicVideo  &&
                            <Chip icon={<AutoAwesome color='success' fontSize='small'/>} label={
                                <Typography component='span' variant='h7' sx={{ fontWeight: 600 }}>
                                    {video.ownerTitle}
                                </Typography>
                            } onClick={() => onOwnerSelected(video)} />
                        }

                        {
                            !video.magicVideo &&
                            <Chip label={
                                <Typography component='span' variant='h7' sx={{ fontWeight: 600 }}>
                                    {video.ownerTitle}
                                </Typography>
                            } onClick={() => onOwnerSelected(video)} />
                        }
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default VideoSubject;
